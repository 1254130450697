import { documentoStore } from './../../../store/modules/documentos/index';
import { defineComponent } from 'vue';
import { DxColumn, DxExport } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../datagrid-factory/index.vue'
export default defineComponent({
  name: 'XmlProcessadas',
  setup(){
    const store = documentoStore();

    return {store}
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxExport
  }
})