<template>
  <DataGridFactory
    :dataSource="store.DocListaRecebimentoXmlProcessado"
    :enabledActions="false"
    :nameToExport="'Xmls Processadas'"
  >
    <template v-slot:column>
      <DxColumn
        data-field="dataRecebimento"
        data-type="date"
        caption="Data Recebimento"
      />
      <DxColumn data-field="protocoloRecebimento" caption="Protocolo" alignment="right" />
      <DxColumn
        data-field="processamento"
        data-type="date"
        caption="Data Processamento"
      />
      <DxColumn data-field="descricao" caption="Arquivo" />
    </template>
  </DataGridFactory>
</template>
<script src="./index.ts"></script>